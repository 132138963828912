import { get, writable } from 'svelte/store';
import { browser } from '$app/environment';

interface Language {
	id: string;
	name: string;
	iso3166_a2: string;
	iso3166_a3: string;
}

export const languages = writable<Language[]>(browser && JSON.parse(localStorage.getItem('languages') || '[]'));

languages.subscribe(value => {
	if (browser) localStorage.setItem('languages', JSON.stringify(value));
});